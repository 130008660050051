<template>
  <main>
    <Navbar v-on:refresh-lang="Refresh"/>


        <RouterView :lang="lang"></RouterView>

  </main>
</template>

<script>
import i18n from "@/i18n";
import Navbar from "@/components/Navbar";
export default {
  name: "Layout",
  components: {Navbar},
  data(){
    return{
      lang:i18n.locale
    }
  },
  methods:{
    Refresh(lang){
      this.lang = lang
      this.$forceUpdate();
    }
  }
}
</script>

<style >

body{
  overflow:  visible!important;
  display: block!important;
  min-height: fit-content!important;
}

</style>