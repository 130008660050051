<template>
  <b-navbar toggleable style="background-color: #2f2d2e">
    <b-navbar-brand class="logo mx-auto btn" @click="$router.push({name:'Home'})">SURVIVE</b-navbar-brand>
    <b-nav-item-dropdown class="mx-3">
      <template v-slot:button-content>
        <img class :src="flag" alt="Header Language" height="25" width="35" />
      </template>
      <b-dropdown-item v-for="(entry, i) in languages"
          :key="`Lang${i}`" :value="entry"
          @click="setLanguage(entry.language, entry.title, entry.flag)"
          :class=" {'active' : lan === entry.language}">
        <img :src="`${entry.flag}`" alt="lang-image" height="25" width="35" />
      </b-dropdown-item>
    </b-nav-item-dropdown>

  </b-navbar>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "Navbar",
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/flags/flag_en.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/flags/flag_fr.svg"),
          language: "fr",
          title: "French",
        },

      ],
      text: null,
      flag: null,
      value: null,
      lan: i18n.locale,
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem('lang',locale)
      this.$emit('refresh-lang',locale)
      this.$forceUpdate()
    },
  }
}
</script>

<style scoped>


/deep/ .navbar-brand.logo{
  font-family: "Monoton";
  color: #942127 ;
  font-size: 30px;
}
/deep/.navbar-light.navbar-brand:hover,:hover{
  color: #f1c6c6!important;
}
/deep/ .dropdown-menu{
  min-width: fit-content!important;
  background-color: #2a2b2f;
}
/deep/ .dropdown-toggle::after{
  display: none!important;
}
/deep/ .b-nav-dropdown{
  list-style-type: none!important;
}
</style>